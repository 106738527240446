<template>
  <div class="contenedor-indicador">
    <Spinner 
      :mostrarSpinner="spinner"
    />

    <!-- Navegqación -> Titulo y boton <- -->
    <div class="indicador-head" v-if="mostrarBoton">
      <div class="indicador-body">
        <h1>Postulaciones internas</h1>
      </div>
    </div>

  <!-- Contenedor para la captura de información -->
    <div 
      class="agregar-indicador"
      v-if="indicadorFormulario"
      >
        <form @submit.prevent="">


          <div class="input-label">
             <label>Mujeres candidatas:</label>
              <input 
                type="String" 
                v-model="contenedorData.mujeres"
                class="form-control-my m-2"
              >
          </div>

          <div class="input-label">
             <label>Hombres candidatos:</label>
              <input 
              type="String" 
              v-model="contenedorData.hombres"
              class="form-control-my m-2"
              >
          </div>

          <div class="input-label">
             <label>Total candidaturas:</label>
              <input 
              type="String" 
              v-model="contenedorData.totales"
              class="form-control-my m-2"
              >
          </div>
         
          <div class="input-label">
             <label>Periodo:</label>
              <input 
              type="option" 
              v-model="contenedorData.periodo"
              class="form-control-my m-2"
              >
          </div>

           <div class="input-label-large">
             <label>Partido Político:</label>
              <input 
              type="String" 
              v-model="contenedorData.partido"
              class="form-control-my m-2"
              >
          </div>
           <div class="input-label-large">
              <label>Municipio:</label>
              <select name="municipio" id="municipio" v-model="contenedorData.municipio">
                <option value="">Selecciona un municipio</option>
                <option value="Todos">Todos los municipios</option>
                <option value="Amacuzac">Amacuzac</option>
                <option value="Atlatlahucan">Atlatlahucan</option>
                <option value="Axochiapan">Axochiapan</option>
                <option value="Ayala">Ayala</option>
                <option value="Coatlán del Río">Coatlán del Río</option>
                <option value="Cuautla">Cuautla</option>
                <option value="Cuernavaca">Cuernavaca</option>
                <option value="Emiliano Zapata">Emiliano Zapata</option>
                <option value="Huitzilac">Huitzilac</option>
                <option value="Jantetelco">Jantetelco</option>
                <option value="Jiutepec">Jiutepec</option>
                <option value="Jojutla">Jojutla</option>
                <option value="Jonacatepec">Jonacatepec</option>
                <option value="Mazatepec">Mazatepec</option>
                <option value="Miacatlán">Miacatlán</option>
                <option value="Ocuituco">Ocuituco</option>
                <option value="Puente de Ixtla">Puente de Ixtla</option>
                <option value="Temixco">Temixco</option>
                <option value="Temoac">Temoac</option>
                <option value="Tepalcingo">Tepalcingo</option>
                <option value="Tepoztlán">Tepoztlán</option>
                <option value="Tetecala">Tetecala</option>
                <option value="Tetela del Volcán">Tetela del Volcán</option>
                <option value="Tlalnepantla">Tlalnepantla</option>
                <option value="Tlaltizapán">Tlaltizapán</option>
                <option value="TlaquiltenangoTlayacapan">TlaquiltenangoTlayacapan</option>
                <option value="Tlayacapan">Tlayacapan</option>
                <option value="Xochitepec">Xochitepec</option>
                <option value="Zacatepec">Zacatepec</option>
                <option value="Zacualpan">Zacualpan</option>
                <option value="Totolapan">Totolapan</option>
                <option value="Yautepec">Yautepec</option>
                <option value="Yecapixtla">Yecapixtla</option>
              </select>
              
          </div>

            <div class="botones-accion">
              <button class="btn btn-danger m-3" @click="limpiarForm" >Limpiar</button>
              <button class="btn btn-primary" @click="addRegistro(contenedorData)">Agregar</button>
            </div>
              
        </form>

        <div class="mensaje-registro" v-if="mensajeContenedor">
          {{ mensajeConfirmacion }}
        </div>      
    </div>
     
  </div>

</template>
<script>


import { defineAsyncComponent } from 'vue'

export default {

  components: {
    BotonAdd: defineAsyncComponent( () => import('../../../components/BotonAdd.vue')),
    Spinner: defineAsyncComponent( () => import('../../../components/Spinner.vue'))
  },
  data() {
    return {
      indicadorData: false,
      indicadorFormulario: false,
      diputaciones: {
        isLoading: true,
      },
      contenedorData:{
        mujeres: null,
        hombres: null,
        totales: null,
        periodo: null,
        partido: null,
        municipio: null,
        usuarioId: 'Administrador-01'
      },
      spinner: true,
      mostrarBoton: false,
      mensajeConfirmacion: null,
      mensajeContenedor: false
    }
  },
  methods: {
    mostrarMenu(){
      
    },
    stopSpinner(){

      setTimeout(() => {
        this.spinner = false
        this.mostrarBoton = true 
        this.indicadorFormulario = true
      },3000);
    },
    cerrarRegistro(){
      this.indicadorFormulario = false
      this.indicadorData = true
    },
    addRegistro( data ){
      const url = '/municipios/proceso-electoral/nuevo/postulaciones-internas'
      this.axios.post(`${ url }`, data )
        .then( res => {
          this.mensajeContenedor = true
          this.mensajeConfirmacion = 'Información agregada correctamente'
          this.limpiarForm()

          setTimeout(() => {
              this.mensajeContenedor = false
              this.mensajeConfirmacion = null
          }, 2000);
          
        })
        .catch( e => {
          console.log( e );
        })

    },
    limpiarForm() {
        this.contenedorData.hombres = ''
          this.contenedorData.mujeres = ''
          this.contenedorData.totales = ''
          this.contenedorData.municipio = ''
          this.contenedorData.periodo = ''
          this.contenedorData.partido = ''
    }
  },
  created(){
    this.stopSpinner()
  }

}
</script>

<style lang="scss">
  .ficha-data{
    width:80%;
    margin:20px auto;
    height:200px;
    background-color: #f1f1f1;
    .header-data{
      padding: 12px 12px;
      display:flex; align-items:center; justify-content: space-between;
    }
    .body-data{
      display: flex;  
    }
  }

  .agregar-indicador{
    width:70%;
    height: calc(100vh - 220px);
    margin:10px auto;
    padding:14px 12px;
    text-align:center;
    
    // background-color: red;
    & p{
      text-align:right;
      margin-right:40px;
      font-size:20px;
      font-weight: bold;
      cursor:pointer;

    }
    form{

      width:90%;
      margin:10px auto;
      display: flex;
      flex-direction: column;

      .input-label{
        
          label{
            width:700px;
            text-align:left;
            // background-color: red;
          }
          input { 
            width: 80px;
            height:50px;
            text-align:center;
          } 
      }

      .input-label-large{

          label{
            width:500px;
            text-align:left;
            // background-color: red;
          }

          input {
                width:280px; 
                height: 30px;
            }
            
          select {
                width:280px; 
                height: 30px;
                margin:20px auto;
          }
   
        }

        button {
          width:200px;
          margin:20px auto;
        }
      
      
    }

    .mensaje-registro {
      width:80%;
      height:50px;
      margin:10px auto;
      font-size:16px;
      font-weight: bold;
      background-color: #f1f1f1;
      display: flex; align-items:center; justify-content:center;
    }

    
  }

  
</style>